import './App.css';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import { Box, Link, Typography } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      light: '#FFFFFF',
      main: '#517555',
      dark: '#000000'
    },
  },
  typography: {
    fontFamily: 'Changa'
  }
});
responsiveFontSizes(theme);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          width: '100vw',
          minHeight: '100vh',
          backgroundColor: 'primary.main',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      ><Typography variant="h1" color='primary.light'>
          Vidux
        </Typography>
        <Typography variant="h3" color='primary.light'>
          IT-consultancy
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Typography variant="body1" color='primary.light'>
            Contacteer ons via email
          </Typography>
          <Typography variant="body1" color='primary.light'>
            &nbsp;
          </Typography>
          <Link href="mailto:dries.vanitterbeeck@vidux.be" variant="body1" color='primary.light'>
            dries.vanitterbeeck@vidux.be
          </Link>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            m: '10px'
          }}
        >
          <Typography variant="body1" color='primary.light'>
            Of geef ons een belletje op
          </Typography>
          <Typography variant="body1" color='primary.light'>
            &nbsp;
          </Typography>
          <Link href="tel:0032497432866" variant="body1" color='primary.light'>
            +32/497 43 28 66
          </Link>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default App;
